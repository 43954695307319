html,
body,
#root {
  height: 100%;
}

button:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(102, 102, 102);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: rgb(70, 69, 66);
  border-radius: 12px;
  margin: 2px 0px;
}
